html.dashboard {

    body {
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        background-image: url('../assets/img/bg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: white;
        overflow-x: hidden;
    }

    .clear:before,
    .clear:after {
        content: " ";
        display: table;
    }

    .clear:after {
        clear: both;
    }

    .pt-table {
        display: table;
        height: -webkit-calc(90vh - 4px);
        height: -moz-calc(90vh - 4px);
        height: calc(90vh - 4px);
    }

    .pt-tablecell {
        display: table-cell;
        vertical-align: middle;
    }

    .page-home {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        vertical-align: middle;
    }

    /* End of container */
    .hexagon-item {
        cursor: pointer;
        width: 200px;
        height: 173.20508px;
        float: left;
        margin-left: -5px;
        z-index: 0;
        position: relative;
        -webkit-transform: rotate(30deg);
        -moz-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
        -o-transform: rotate(30deg);
        transform: rotate(30deg);
        margin-bottom: 40px;
    }

    .hexagon-item:hover {
        z-index: 1;
    }

    .hexagon-item:hover .hex-item:last-child {
        opacity: 1;
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -o-transform: scale(1.3);
        transform: scale(1.3);
    }

    .hexagon-item:hover .hex-item:first-child {
        opacity: 1;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }

    .hexagon-item:hover .hex-item:first-child div:before,
    .hexagon-item:hover .hex-item:first-child div:after {
        height: 5px;
    }

    .hexagon-item:hover .hex-item div::before,
    .hexagon-item:hover .hex-item div::after {
        background-color: white;
    }

    .hexagon-item:hover .hex-content svg {
        -webkit-transform: scale(0.97);
        -moz-transform: scale(0.97);
        -ms-transform: scale(0.97);
        -o-transform: scale(0.97);
        transform: scale(0.97);
    }

    .hex-item {
        position: absolute;
        top: 0;
        left: 50px;
        width: 100px;
        height: 173.20508px;
    }

    .hex-item:first-child {
        z-index: 0;
        -webkit-transform: scale(0.9);
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        -o-transform: scale(0.9);
        transform: scale(0.9);
        -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .hex-item:last-child {
        transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
        z-index: 1;
    }

    .hex-item div {
        box-sizing: border-box;
        position: absolute;
        top: 0;
        width: 100px;
        height: 173.20508px;
        -webkit-transform-origin: center center;
        -moz-transform-origin: center center;
        -ms-transform-origin: center center;
        -o-transform-origin: center center;
        transform-origin: center center;
    }

    .hex-item div::before,
    .hex-item div::after {
        background-color: white;
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
        -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
        -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    }

    .hex-item div:before {
        top: 0;
    }

    .hex-item div:after {
        bottom: 0;
    }

    .hex-item div:nth-child(1) {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    .hex-item div:nth-child(2) {
        -webkit-transform: rotate(60deg);
        -moz-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
        -o-transform: rotate(60deg);
        transform: rotate(60deg);
    }

    .hex-item div:nth-child(3) {
        -webkit-transform: rotate(120deg);
        -moz-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
        -o-transform: rotate(120deg);
        transform: rotate(120deg);
    }

    .hex-content {
        color: #fff;
        display: block;
        height: 180px;
        margin: 0 auto;
        position: relative;
        text-align: center;
        transform: rotate(-30deg);
        width: 156px;
        font-weight: 500;
    }

    .hex-content .hex-content-inner {
        left: 50%;
        margin: -3px 0 0 2px;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .hex-content .icon {
        display: block;
        font-size: 36px;
        line-height: 30px;
        margin-bottom: 11px;
    }

    .hex-content .title {
        display: block;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 24px;
        text-transform: uppercase;
    }

    .hex-content svg {
        left: -7px;
        position: absolute;
        top: -13px;
        transform: scale(0.87);
        z-index: -1;
        -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
        -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
        -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    }

    .hex-content:hover {
        color: #fff;
    }

    .author-image-large {
        position: absolute;
        right: 0;
        top: 0;
    }

    .author-image-large img {
        height: -webkit-calc(100vh - 4px);
        height: -moz-calc(100vh - 4px);
        height: calc(100vh - 4px);
    }

    .hexagon-item:hover .icon i {
        color: white;
        transition: 0.6s;

    }

    .hexagon-item:hover .title {
        -webkit-animation: focus-in-contract 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: focus-in-contract 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes focus-in-contract {
        0% {
            letter-spacing: 1em;
            -webkit-filter: blur(12px);
            filter: blur(12px);
            opacity: 0;
        }

        100% {
            -webkit-filter: blur(0px);
            filter: blur(0px);
            opacity: 1;
        }
    }

    @keyframes focus-in-contract {
        0% {
            letter-spacing: 1em;
            -webkit-filter: blur(12px);
            filter: blur(12px);
            opacity: 0;
        }

        100% {
            -webkit-filter: blur(0px);
            filter: blur(0px);
            opacity: 1;
        }
    }
}