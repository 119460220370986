$primary: #032659;

@import "~bootstrap/scss/bootstrap";
@import 'boxicons/css/boxicons.css';
@import "toastr";

/* Páginas */
@import "_login";
@import "_dashboard";
@import "_jogos";
@import "_jogo";



.navbar {
    margin-left: -12px;
    margin-right: -12px;
}

hr {
    height: 1px;
    border: 1px solid #032659;
    background-color: #032659;
    opacity: 1;
}

.loading {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #fcfcfc;
    filter: alpha(opacity=90);
    width: 100vw;
    height: 100vh;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    opacity: 0.90;
    z-index: 9997;

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 4em;
        color: #032659;
    }
}