html.jogo {
    .penalti {
        input[type="checkbox"] {
            scale: 1.5;
            margin-top: 0.55rem;
        }
    }

    #ponto-a,
    #ponto-b {
        background-color: #032659;
        padding: .3rem .8rem;
        color: #fff;

        sup {
            margin-left: .2rem;
        }
    }

    #ponto-b {
        sup {
            margin-left: 0rem;
            margin-right: .2rem;
        }
    }

    .nome-equipe {
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: right;

        .logo-equipe {
            width: 48px;
            height: 48px;
            background-color: var(--wp--preset--color--white);
            border: 1px solid #707070;
            border-radius: 50%;
            overflow: hidden;
            margin-left: 1rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &.left {
            justify-content: left;

            .logo-equipe {
                margin-left: 0;
                margin-right: 1rem;
            }
        }
    }

    .cartao-amarelo {
        &.selected {
            background-color: #FFE56C;
            font-weight: bold;
        }
    }

    .cartao-azul {
        &.selected {
            background-color: #2E71FF;
            color: #fff;
            font-weight: bold;
        }
    }

    .cartao-vermelho {
        &.selected {
            background-color: #FF7D6C;
            font-weight: bold;
        }
    }
    
    .num-gol {
        border-left: #032659 1px solid;
        text-align: right;
    }

    .editar-gol {
        a {
            i {
                font-size: 1.5rem;
            }
        }
    }

    td.penalti {
        font-weight: 500;
        color: #032659;
    }
}